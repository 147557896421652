// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-abonnement-js": () => import("./../../../src/pages/abonnement.js" /* webpackChunkName: "component---src-pages-abonnement-js" */),
  "component---src-pages-annulation-commande-js": () => import("./../../../src/pages/annulation-commande.js" /* webpackChunkName: "component---src-pages-annulation-commande-js" */),
  "component---src-pages-au-fil-de-l-eau-js": () => import("./../../../src/pages/au-fil-de-l-eau.js" /* webpackChunkName: "component---src-pages-au-fil-de-l-eau-js" */),
  "component---src-pages-commande-js": () => import("./../../../src/pages/commande.js" /* webpackChunkName: "component---src-pages-commande-js" */),
  "component---src-pages-confirmation-commande-js": () => import("./../../../src/pages/confirmation-commande.js" /* webpackChunkName: "component---src-pages-confirmation-commande-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-numeros-js": () => import("./../../../src/pages/numeros.js" /* webpackChunkName: "component---src-pages-numeros-js" */),
  "component---src-templates-article-blocked-js": () => import("./../../../src/templates/article-blocked.js" /* webpackChunkName: "component---src-templates-article-blocked-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-au-fil-article-js": () => import("./../../../src/templates/AuFilArticle.js" /* webpackChunkName: "component---src-templates-au-fil-article-js" */),
  "component---src-templates-materiau-js": () => import("./../../../src/templates/materiau.js" /* webpackChunkName: "component---src-templates-materiau-js" */),
  "component---src-templates-materiau-single-js": () => import("./../../../src/templates/materiau-single.js" /* webpackChunkName: "component---src-templates-materiau-single-js" */)
}

